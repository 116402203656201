import React, {useState} from 'react'
import Logo from '../logo.ico'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from "axios";
import {useHistory} from "react-router-dom";
import Loading from "../component/Loading";
import Alert from '@material-ui/lab/Alert';
import {Redirect} from "react-router-dom";
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}{new Date().getFullYear()}
            <Link color="inherit" href="/register">
                Created By Fosti With ❤️

            </Link>{' | '}
            <Link href="https://github.com/FOSTI-UMS/fostiers">Klik For Bug report</Link>

        </Typography>
    );
}
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        width:100,
        height:100,
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

/*
	Nama		string
	NIM			string
	Angkatan	string
	Fakultas	string
	Provinsi	string
	Kabupaten	string
	Password	string
 */

export default function Login() {

    const classes = useStyles();
    const [nim, setNim] = React.useState('');
    const [password, setPassword] = React.useState('');
    const handleNim = (event) => {
        setNim(event.target.value.toUpperCase());
    };
    const handlePass = (event) => {
        setPassword(event.target.value);
    };
    const handleLogin = (event) => {
        setLoading(false)
        login()
    };
    const [loading,setLoading] = useState(true)
    const [berhasil,setBerhasil] = useState(false)
    const login = () => {
        axios.post('https://api.robohox.com/api/auth', 
        {
            nim:nim,
            password:password
        })
            .then((response) => {
                console.log(response.data.data);
                if(response.data.data.message == "Login Success"){
                    localStorage.setItem("Token",response.data.data.token.jwt)
                    setTimeout(function() { //Start the timer
                        setBerhasil(true)
                        history.push("/")
                        window.location.reload()
                    }.bind(this), 3000)
                }else{
                    alert("Check Username/Password")
                    setLoading(true)
                }

            }, (error) => {
                setLoading(true)
            });
    }
    const history = useHistory();

 

    return(

        <Container component="main" maxWidth="xs">
            <CssBaseline />
            
            <div className={classes.paper}>
                <Avatar className={classes.avatar} src={Logo}>

                </Avatar>
                {
                    loading?
                    <Typography component="h1" variant="h5">
                        Login MyFOSTI
                    </Typography>
                    :
                        berhasil?
                            <Typography component="h1" variant="h5">
                                Berhasil Masuk :)
                            </Typography>:
                    <Typography component="h1" variant="h5">
                    Membawamu Masuk
                    </Typography>
                }
                {
                loading?
                    <form className={classes.form} noValidate>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="NIM"
                                    onChange={handleNim}
                                    value={nim}
                                    autoFocus
                                    
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    onChange={handlePass}
                                    value={password}
                                    autoComplete="current-password"
                                />
                            </Grid>

                        </Grid>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleLogin}
                        >
                            Masuk
                        </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    Belum punya akun? Daftar Sinih
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                    :
                    berhasil?<Redirect to="/dashboard" />:<Loading/>
                }
            </div>
            <Box mt={5}>
                <Copyright/>
            </Box>
        </Container>
    )
}