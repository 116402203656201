import React,{useEffect} from 'react'
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import Logo from "../logo.ico";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Loading from "../component/Loading";
import { DataGrid } from '@material-ui/data-grid';
import axios from "axios";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import jwt_decode from "jwt-decode";
import CsvDownload from 'react-json-to-csv'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
    },
    avatar: {
        width:100,
        height:100,
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
export default function Users() {
    const classes = useStyles();
    const [age, setAge] = React.useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [rows,setRows] = React.useState([])
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const downloadimg = linke => {
        var link = document.createElement('a');
        link.href = linke+'.jpg';
        link.download = linke+".jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const goDownloadImg = (e) => {
        rows.map(function(item, i){
            console.log('test');
            downloadimg(item.nim)
        })
    };

    const columns = [
        { field: 'id', headerName: 'ID'},
        { field: 'nim', headerName: 'NIM',flex:1},
        { field: 'nama', headerName: 'Nama',flex:1},
        {field: 'fakultas',headerName: 'Fakultas',type: '',flex:1},
        {field: 'angkatan',headerName: 'Angkatan',type: '',flex:1},
        {field: 'lahir',headerName: 'Lahir',type: '',flex:1},
        {field: 'email',headerName: 'Email',type: '',flex:1},
        {field: 'alamat',headerName: 'Alamat',type: '',flex:1},
        {field: 'nohp',headerName: 'Password',type: '',flex: 1}

    ];

    useEffect(() => {
        getData()
    },[]);

    const getData = () => {
        axios.get('https://api.robohox.com/api/user', 
        {
            headers: {
                Token: localStorage.getItem("Token")
            }
        })
            .then((response) => {
                const rowsi = [];
                console.log(response.data.data);
                response.data.data.map(function(item, i){
                    console.log('test');
                    rowsi.push({ id: i, nim: item.nim, nama: item.id, fakultas: item.fakultas,angkatan:item.angkatan,lahir:item.tgl_lahir,email:item.email,alamat:item.alamat,nohp:item.hp})
                })
                setRows(rowsi)

            }, (error) => {
                alert("Coba lagi beberapa saat kemudian...")
            });
    };


    return(
        <Container component="main">
            <CssBaseline />
            <div className={classes.paper}>
            

                <h2 style={{textAlign:"left"}}>Anggota Terdaftar
                    |&nbsp;
                    <Button variant="contained" color="#ffff" style={{borderRadius:15}}>
                        <CsvDownload style={{backgroundColor:"#ffff"}} filename="Dataanggotafosti.csv" data={rows} >Download Data</CsvDownload>
                    </Button>
                    
                    &nbsp;|&nbsp;
                    <Button variant="contained" color="#ffff" onClick={goDownloadImg} style={{borderRadius:15}}>
                        Download Gambar
                    </Button>
                </h2>

                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} pageSize={10} />
                </div>
            </div>

        </Container>
    )
}