import React, {useState,useEffect} from 'react'
import Register from "./page/Register";
import Nav from "./page/Nav";
import Home from "./page/Home";
import Loading from "./component/Loading";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect, useHistory
} from "react-router-dom";
import Login from "./page/Login";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

function App() {
    const [logedIn,setLogedIn] = useState(false)
    const [tema,setTema] = useState(true)

    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: tema ? 'dark' : 'light',
                },
            }),
        [tema],
    );

    useEffect(() => {
        if(localStorage.getItem("Token") != null){
            setLogedIn(true)
        }else{
            setLogedIn(false)
        }
    });

  return (
      <ThemeProvider theme={theme}>
        <Router>
            <Switch>
                <Route exact path="/register">
                    {()=>{localStorage.setItem("nim","")}}
                    {()=>{localStorage.setItem("sudah_foto",0)}}
                    <Register/>
                </Route>
                <Route exact path="/login">
                    <Login/>
                </Route>
                <Route path="/dashboard">
                    {logedIn?<Home/>:<Login/>}
                </Route>
                <Route path="/">
                    {()=>{localStorage.setItem("nim","")}}
                    {()=>{localStorage.setItem("sudah_foto",0)}}
                    {logedIn?<Redirect to="/dashboard" />:<Login/>}

                </Route>
            </Switch>
        </Router>
      </ThemeProvider>
  );
}

export default App;
