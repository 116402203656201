import http from "../http-common";
import jwt_decode from "jwt-decode";

class UploadFiles {
    upload(file, onUploadProgress) {
        let formData = new FormData();
        var nim = jwt_decode(localStorage.getItem("Token")).Nim
        formData.append("foto", file);
        formData.append("nim", nim);
        return http.post("https://api.robohox.com/api/uploadfoto", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }
    getFiles() {
        
        return http.get("/foto");
    }
}

export default new UploadFiles();