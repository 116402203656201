import React, {useState} from 'react'
import Logo from '../logo.ico'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import PhotoUploader from 'react-photo-uploader'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";


import {KeyboardDatePicker} from '@material-ui/pickers';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import Loading from "../component/Loading";
import axios from "axios";
import Success from "../component/Success";
import {useHistory} from "react-router-dom";
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}{new Date().getFullYear()}
            <Link color="inherit" href="/register">
            Created By Fosti With ❤️

            </Link>{' | '}
            <Link href="https://github.com/FOSTI-UMS/fostiers">Klik For Bug report</Link>

        </Typography>
    );
}
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        width:100,
        height:100,
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

/*

	Nama		string
	NIM			string
	Angkatan	string
	Fakultas	string
	Provinsi	string
	Kabupaten	string
	Password	string
 */

export default function Register() {
    const classes = useStyles();
    const [nim, setNim] = React.useState('');
    const [nama, setNama] = React.useState('');
    const [fakultas, setFakultas] = React.useState('');
    const [angkatan, setAngkatan] = React.useState('');
    const [tgllahir, setTglLahir] = React.useState(new Date('2014-08-18T21:11:54'));
    const [alamat, setAlamat] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [email, setEmail] = React.useState('');
    const handleAngkatan = (event) => {
        setAngkatan(event.target.value);
    };
    const handleNama = (event) => {
        setNama(event.target.value);
    };
    const handleEmail = (event) => {
        setEmail(event.target.value);
    };
    const handleNIM = (event) => {
        setNim(event.target.value.toUpperCase());
        localStorage.setItem("nim",event.target.value.toUpperCase())
    };
    const handleFakultas = (event) => {
        setFakultas(event.target.value);
    };
    const handleTgllahir = (event) => {
        setTglLahir(event.target.value);
    };
    const handleAlamat = (event) => {
        setAlamat(event.target.value);
    };
    const handlePassword = (event) => {
        setPassword(event.target.value);
    };
    const [loading,setLoading] = useState(true)
    const [isfoto,setIsfoto] = useState(false)
    const [berhasil,setBerhasil] = useState(false)
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

    const handleDateChange = (date) => {

            setSelectedDate(date);
            setTglLahir(date)

    };

    const validasi = () => {
        if(nama!="" && nim!="" && fakultas!="" && password!="" && alamat!="" && email!=""){
            return true
        }else{
            return false
        }
    }

    const register = () => {
    if(validasi()){
        axios.post('https://api.robohox.com/api/auth', 
        {
            nim:nim,
            password:password
        })
            .then((response) => {
                console.log(response.data.data);
                if(response.data.data.message == "User Not Found"){
                    
                    
                    
                    axios.post('https://api.robohox.com/api/user', {
                        nama:nama,
                        nim:nim,
                        angkatan:angkatan,
                        fakultas:fakultas,
                        password:password,
                        alamat:alamat,
                        tgl_lahir:tgllahir,
                        email:email,
                        hp:password
                    })
                        .then((response) => {
                            console.log(response);
                                setBerhasil(true)
                                history.push("/dashboard")
            
                        }, (error) => {
                            setLoading(true)
                    });

                }else{
                    
                    alert("NIM Sudah Terdaftar, Hubungi administrator")
                    history.push("/register")
                    window.location.reload()
                }

            }, (error) => {
                setLoading(true)
                
        });
    }else{
        alert("Lengkapi Formnya")
        setLoading(true)
    }

    }
    const history = useHistory();

    const daftar = () => {
        var isfoto = localStorage.getItem("sudah_foto")
            setLoading(false)
            register()

    }
    return(

        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                        <img src="http://fostiums.org/static/media/favicon.e736f170.ico" style={{width:"100%"}} />
                </Avatar>
                {
                    loading?
                    <Typography component="h1" variant="h5">
                        Daftar MyFOSTI
                    </Typography>
                    :
                        berhasil?
                            <Typography component="h1" variant="h5">
                                Berhasil Terdaftar :)
                            </Typography>:
                    <Typography component="h1" variant="h5">
                    Mendaftarkan Kamu
                    </Typography>
                }
                {
                    loading?
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="NIM"
                                    autoFocus
                                    value={nim}
                                    onChange={handleNIM}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Nama Lengkap"
                                    name="lastName"
                                    autoComplete="lname"
                                    value={nama}
                                    onChange={handleNama}

                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Fakultas"
                                    name="lastName"
                                    autoComplete="lname"
                                    value={fakultas}
                                    onChange={handleFakultas}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl variant="outlined" style={{width: "100%"}}>
                                    <InputLabel id="demo-simple-select-outlined-label">Angkatan</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={angkatan}
                                        onChange={handleAngkatan}
                                        label="Angkatan">

                                        <MenuItem value="10">2018</MenuItem>
                                        <MenuItem value="20">2019</MenuItem>
                                        <MenuItem value="30">2020</MenuItem>
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{width: "100%"}}
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Tanggal Lahir"
                                        format="MM/dd/yyyy"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Alamat (Provinsi,Kabupaten)"
                                    name="lastName"
                                    autoComplete="lname"
                                    value={alamat}
                                    onChange={handleAlamat}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={handleEmail}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={handlePassword}
                                />
                            </Grid>

                        </Grid>
                        {/* <Grid item xs={12}>
                            <div style={{marginTop: 10, marginBottom: 10}}>
                                <UploadFiles />
                            </div>


                        </Grid> */}
                        <Button
                            fullWidth
                            type="button"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => {
                                daftar()
                            }}
                        >
                            Daftar
                        </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link onClick={()=>{history.push("/login")}} variant="body2">
                                    Sudah Punya Akun? Login Sinih
                                </Link>
                            </Grid>
                        </Grid>
                    </form>

                    :
                        berhasil?<Success/>:<Loading/>
                }
            </div>
            
            <Box pt={4}>
                <Copyright />
            </Box>

        </Container>
    )
}